import { faFilter, faSignOutAlt, faSortAmountUp, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import './dashboard.scss'
import DeleteModal from './deleteModal';
import ProductModal from './product/productModal';
import UserCard from './user/userCard';
import UserModal from './user/userModal';
import { setToken } from '../../redux/actions';
import UserCardSkeleton from './user/userCardSkeleton';

import logo from '../../images/logo_green.png'
import FilterModal from './user/filterModal';
import SortModal from './user/sortModal';
import GraphicSkeleton from './graphic/graphicSkeleton';
import Graphic from './graphic/graphic';
import ProductTab from './product/productTab';
import ActivateModal from './activateModal';

export default function Dashboard() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const token = useSelector(state => state.token)

    const [showUserModal, setShowUserModal] = useState(false)
    const [isNewUser, setIsNewUser] = useState(true)

    const [showProductModal, setShowProductModal] = useState(false)
    const [isNewProduct, setIsNewProduct] = useState(true)

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showActivateModal, setShowActivateModal] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showSortModal, setShowSortModal] = useState(false)

    const [users, setUsers] = useState([])
    const [usersFiltered, setUsersFiltered] = useState([])
    const [userSelected, setUserSelected] = useState(null)
    const [usersLoading, setUsersLoading] = useState(false)

    const [products, setProducts] = useState([])
    const [productSelected, setProductSelected] = useState(null)
    const [productsLoading, setProductsLoading] = useState(false)

    const [kpis, setKpis] = useState(null)
    const [kpisLoading, setKpisLoading] = useState(false)
    
    const [sortUser, setSortUser] = useState('lastLogin')
    const [justSorted, setJustSorted] = useState(false)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getUsers()
        getProducts()
        getKpis()
    }, [])

    useEffect(() => {
        setUsersFiltered(users)
    }, [users])

    useEffect(() => {
        if(justSorted){
            setJustSorted(false)
        }
        else{
            sort()
        }
    }, [sortUser, usersFiltered])

    const getUsers = () => {
        setUsersLoading(true)
        fetch(process.env.REACT_APP_API+'/users', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
            },
        })
        .then(res => {return res.json()})
        .then(json => {
            if(json.success){
                setUsers(json.users)
                setUsersLoading(false)
            }
            else{
                setUsersLoading(false)
                if(json.statusCode === 401){
                    disconnect()
                }
            }
        })
        .catch(err => {
            setUsersLoading(false)
        })
    }

    const getProducts = () => {
        setProductsLoading(true)
        fetch(process.env.REACT_APP_API+'/products', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
            },
        })
        .then(res => {return res.json()})
        .then(json => {
            if(json.success){
                setProducts(json.products)
                setProductsLoading(false)
            }
            else{
                setProductsLoading(false)
                if(json.statusCode === 401){
                    disconnect()
                }
            }
        })
        .catch(err => {
            setProductsLoading(false)
        })
    }

    const getKpis = () => {
        setKpisLoading(true)
        fetch(process.env.REACT_APP_API+'/kpis', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+token
            },
        })
        .then(res => {return res.json()})
        .then(json => {
            if(json.success){
                setKpis(json.kpis)
                setKpisLoading(false)
            }
            else{
                setKpisLoading(false)
                if(json.statusCode === 401){
                    disconnect()
                }
            }
        })
        .catch(err => {
            setKpisLoading(false)
        })
    }

    const activateUser = () => {
        fetch(process.env.REACT_APP_API+'/users/enable/'+userSelected.uuid, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer '+token
            },
        })
        .then(res => {return res.json()})
        .then(json => {
            if(json.success){
                getUsers()
                hideActivateModal()
            }
        })
        .catch(err => {
        })
    }

    const deactivateUser = () => {
        setLoading(true)
        fetch(process.env.REACT_APP_API+'/users/'+userSelected.uuid, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer '+token
            },
        })
        .then(res => {return res.json()})
        .then(json => {
            if(json.success){
                getUsers()
                hideDeleteModal()
                setLoading(false)
            }
            else{
                setLoading(false)
            }
        })
        .catch(err => {
            setLoading(false)
        })
    }
    
    const removeProduct = () => {
        setLoading(true)
        fetch(process.env.REACT_APP_API+'/products/'+productSelected.uuid, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
            },
        })
        .then(res => {return res.json()})
        .then(json => {
            if(json.success){
                getProducts()
                hideProductModal()
                hideDeleteModal()
                setLoading(false)
            }
            else{
                setLoading(false)
            }
        })
        .catch(err => {
            setLoading(false)
        })
    }

    const displayUserAdding = () => {
        setIsNewUser(true)
        setShowUserModal(true)
        document.body.classList.add('modal-open')
    }

    const displayUserEditing = (user) => {
        setUserSelected(user)
        setIsNewUser(false)
        setShowUserModal(true)
        document.body.classList.add('modal-open')
    }

    const displayUserReactivating = (user) => {
        setUserSelected(user)
        setShowActivateModal(true)
        document.body.classList.add('modal-open')
    }

    const displayUserDeleting = (user) => {
        setUserSelected(user)
        setIsNewUser(false)
        setShowDeleteModal(true)
        document.body.classList.add('modal-open')
    }

    const displayProductAdding = () => {
        setIsNewProduct(true)
        setShowProductModal(true)
        document.body.classList.add('modal-open')
    }

    const displayProductEditing = (product) => {
        setProductSelected(product)
        setIsNewProduct(false)
        setShowProductModal(true)
        document.body.classList.add('modal-open')
    }

    const displayDeleteModal = () => {
        setShowDeleteModal(true)
        document.body.classList.add('modal-open')
    }

    const hideUserModal = () => {
        setShowUserModal(false)
        document.body.classList.remove('modal-open')
    }

    const hideProductModal = () => {
        setShowProductModal(false)
        document.body.classList.remove('modal-open')
    }

    const hideDeleteModal = () => {
        setShowDeleteModal(false)
        document.body.classList.remove('modal-open')
    }

    const hideActivateModal = () => {
        setShowActivateModal(false)
        document.body.classList.remove('modal-open')
    }

    const disconnect = () => {
        dispatch(setToken(''))
        navigate('/')
    }

    const openFilterModal = () => {
        setShowSortModal(false)
        setShowFilterModal(!showFilterModal)
    }

    const filter = (noEstimate, estimates, deactivatedUsers, activatedUsers, admins) => {
        const filterResult = users.filter(user => {
            if(!noEstimate && !estimates && !deactivatedUsers && !activatedUsers && !admins){
                return user
            }
            let condition = (noEstimate ? user.devis_count === 0 : true) 
                        && (estimates ? user.devis_count > 0 : true)
                        && (deactivatedUsers ? user.is_archived : true)
                        && (activatedUsers ? !user.is_archived : true)
                        && (admins ? user.is_admin : true)

            if(condition){
                return user
            }
        })
        setUsersFiltered(filterResult)
    }

    const openSortModal = () => {
        setShowFilterModal(false)
        setShowSortModal(!showSortModal)
    }

    const sort = () => {
        const sortedResult = usersFiltered.sort((a, b) => {
            switch(sortUser){
                case 'alphabeticalOrderAsc':
                    if(a.first_name < b.first_name) { 
                        return -1 
                    }
                    if(a.first_name > b.first_name) { 
                        return 1 
                    }
                    return 0
                    
                case 'estimatesCountDesc':
                    if(a.devis_count > b.devis_count) { 
                        return -1 
                    }
                    if(a.devis_count < b.devis_count) { 
                        return 1 
                    }
                    return 0

                case 'lastLogin':
                    if(new Date(a.last_login_date) > new Date(b.last_login_date)) { 
                        return -1 
                    }
                    if(new Date(a.last_login_date) < new Date(b.last_login_date)) {
                        return 1 
                    }
                    return 0
                    
                case 'firstLogin':
                    if(new Date(a.last_login_date) < new Date(b.last_login_date)) { 
                        return -1 
                    }
                    if(new Date(a.last_login_date) > new Date(b.last_login_date)) { 
                        return 1 
                    }
                    return 0
                default:
                    return 0
            }
        })
        setJustSorted(true)
        setUsersFiltered([...sortedResult])
    }
    
    return (
        <>
        <Container className='backgroundPrimary d-flex justify-content-center'>
            <div className='dashboard d-flex'>
                <div className='d-flex flex-column usersSection'>

                    <div className='d-flex align-items-center dashboardTitle'>
                        <img style={{marginRight: 10}} src={logo} alt='' height={40} />
                        <div style={{marginRight: 20}} className='title'>Tableau de bord Humutrace</div>
                        <div onClick={disconnect} className='signOutSquare'>
                            <FontAwesomeIcon icon={faSignOutAlt} width={25} height={25} color='white' />
                        </div>
                    </div>

                    <div className='d-flex kpis' style={{marginTop: 20}}>
                        <div className='kpiNumbers' >
                            <div className='d-flex' style={{justifyContent: 'space-between', marginBottom: 20}}>
                                <div style={{marginRight: 20}} className='greenSquare'>
                                    <p className='bigWhiteText'>Simu.</p>
                                    <p className='bigWhiteText'>{kpis?.sim_and_dev_count_for_30_days.sim_count ?? '0'}</p>
                                </div>

                                <div className='greenSquare'>
                                    <p className='bigWhiteText'>Devis</p>
                                    <p className='bigWhiteText'>{kpis?.sim_and_dev_count_for_30_days.dev_count ?? '0'}</p>
                                </div>
                            </div>

                            <p className='basicText'>Graphique du nombre de devis et simulations effectués sur les 7 derniers jours</p>
                        </div>
                        
                        <div className='flex-column' style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                            {kpisLoading ? 
                                <GraphicSkeleton />
                            :
                                <Graphic kpis={kpis} />
                            }
                        </div>
                    </div>

                    <div style={{marginTop: 20}} className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex'>
                            <div style={{marginRight: 20}} className='title'>Utilisateurs</div>
                            <div className='d-flex align-items-center justify-center squareNumber'>{usersFiltered.length}</div>
                            <div onClick={displayUserAdding} className='greenCircle'>
                                <FontAwesomeIcon icon={faUserPlus} width={15} height={15} color='white' />
                            </div>
                        </div>

                        <div className='d-flex'>
                            <div onClick={openFilterModal} style={{marginRight: 20, cursor: 'pointer'}} className='d-flex align-items-center'>
                                <div className='greyText' style={{marginRight: 10}}>Filtres</div>
                                <FontAwesomeIcon color='#545454' icon={faFilter} height={20} width={20} />
                            </div>
                            <div onClick={openSortModal} style={{cursor: 'pointer'}} className='d-flex align-items-center'>
                                <div className='greyText' style={{marginRight: 10}}>Tris</div>
                                <FontAwesomeIcon color='#545454' icon={faSortAmountUp} height={20} width={20} />
                            </div>
                        </div>
                    </div>

                    <div style={{position: 'relative'}}>
                        <div className='usersList'>
                            {usersLoading ? 
                                <UserCardSkeleton />
                            : 
                                usersFiltered.length ?
                                    usersFiltered.map(user => {
                                        return(
                                            <UserCard key={user.uuid} user={user} onReactivate={() => displayUserReactivating(user)} onDelete={() => displayUserDeleting(user)} onEdit={() => displayUserEditing(user)} />
                                        )
                                    })
                                :
                                    <div style={{textAlign: 'center'}} className='greyText'>{"Pas d'utilisateurs trouvés" + (users.length ? " avec ces filtres. Essayez une autre combinaison." : ".")}</div>
                            }
                        </div>
                        <div style={{zIndex: showFilterModal || showSortModal ? 1 : -1}} className='userMask' />
                        <FilterModal filter={(noEstimate, estimates, deactivatedUsers, activatedUsers, admins) => filter(noEstimate, estimates, deactivatedUsers, activatedUsers, admins)} dismiss={() => setShowFilterModal(false)} show={showFilterModal} />
                        <SortModal sortUser={sortUser} setSortUser={setSortUser} dismiss={() => setShowSortModal(false)} show={showSortModal} />
                    </div>

                </div>

                <div className='d-flex flex-column productsSection'>
                    <div className='d-flex align-items-center'>
                        <div style={{marginRight: 20}} className='title'>Produits</div>
                        <div className='d-flex align-items-center justify-center squareNumber'>{products.length}</div>
                    </div>
                    <ProductTab products={products} productsLoading={productsLoading} displayProductEditing={displayProductEditing} displayProductAdding={displayProductAdding} />
                </div>
            </div>
            
        </Container>
        <UserModal refresh={getUsers} user={userSelected} isNew={isNewUser} show={showUserModal} dismiss={hideUserModal} />
        <ProductModal refresh={getProducts} product={productSelected} onDelete={displayDeleteModal} isNew={isNewProduct} show={showProductModal} dismiss={hideProductModal} />
        <DeleteModal loading={loading} isProduct={showProductModal} remove={showProductModal ? removeProduct : deactivateUser} show={showDeleteModal} cancel={showProductModal ? () => setShowDeleteModal(false) : hideDeleteModal} />
        <ActivateModal reactivate={activateUser} show={showActivateModal} cancel={hideActivateModal} />
        </>
    )
}
