import { faArrowLeft, faMailBulk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import ButtonRectangle from '../../components/buttonRectangle'

export default function ForgottenPassword() {
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const sendEmail = () => {
        setLoading(true)
        fetch(process.env.REACT_APP_API+'/send-forgot-password-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email
            })
        })
        .then(res => {return res.json()})
        .then(json => {
            setErrorMessage(json.message)
            setLoading(false)
        })
        .catch(err => {
            setLoading(false)
            setErrorMessage("Une erreur s'est produite")
        })
    }

    const handleKeyPress = (key) => {
        if(key === 'Enter'){
            sendEmail()
        }
    }

    return (
        <Container className='children-centered backgroundPrimary'>
            <div style={{maxWidth: 500}} className={'loginContainer d-flex flex-column align-items-center'}>
                <div onClick={() => navigate('/login')} style={{cursor: 'pointer'}} className='d-flex align-items-center align-self-start'>
                    <FontAwesomeIcon color='#545454' style={{marginRight: 10}} height={20} width={20} icon={faArrowLeft} />
                    <div className='greyText'>Retour</div>
                </div>

                <div className='title mt-3'>Réinitialisation du mot de passe</div>

                <div className='greyText mt-3'>Veuillez saisir votre adresse e-mail liée à votre compte pour réinititaliser votre mot de passe. Un e-mail avec un code vous sera envoyé.</div>

                <div className='d-flex align-items-center' style={{marginTop: 20}}>
                    <FontAwesomeIcon className='iconPrimary' style={{position: 'absolute', marginLeft: 20}} icon={faMailBulk} width={20} height={20} />
                    <input onKeyPress={event => handleKeyPress(event.key)} onChange={(event) => setEmail(event.target.value)} value={email} type='text' className='loginInput' placeholder="Adresse e-mail"></input>
                </div>

                <ButtonRectangle loading={loading} onClick={sendEmail} disabled={!email.length || loading} style={{marginTop: 20}} color='green' text='Réinitialiser' />
                <div style={{marginTop: 10}} className='errorMessage'>{errorMessage}</div>
            </div>
        </Container>
    )
}
