import React from 'react'
import ButtonRectangle from '../../../components/buttonRectangle'
import ProductRow from './productRow'
import ProductRowSkeleton from './productRowSkeleton'

import './product.scss'

export default function ProductTab({products, displayProductAdding, displayProductEditing, productsLoading}) {
    return (
        <div style={{marginTop: 20}} className='productsTab'>
            <div style={{width: '100%'}}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{flex: 1}}>
                        <div className='tabTitle'>Nom</div>
                    </div>
                    <div style={{flex: 1}}>
                        <div className='tabTitle'>Tarif</div>
                        <div className='basicText'>€/T départ</div>
                    </div>
                    <div style={{flex: 3, justifyContent: 'space-around', display: 'flex'}}>
                        <div className='tabTitle'>Blé</div>
                        <div className='tabTitle'>Colza</div>
                        <div className='tabTitle'>Bett.</div>
                        <div className='tabTitle'>PdT</div>
                    </div>
                </div>
                {productsLoading ? 
                    <ProductRowSkeleton />
                : 
                    products.length ? 
                        products.map((product, i) => {
                            return(
                                <ProductRow 
                                    key={product.uuid} 
                                    border={i > 0} 
                                    onClick={() => displayProductEditing(product)} 
                                    name={product.name}
                                    price={product.price} 
                                    wheat={product.dose_per_hectare_for_wheat} 
                                    rapeseed={product.dose_per_hectare_for_rapeseed} 
                                    beet={product.dose_per_hectare_for_beet} 
                                    potato={product.dose_per_hectare_for_potato} 
                                />
                            )
                        })
                        :
                        <div style={{textAlign: 'center'}} className='greyText'>Pas de produits trouvés.<br/>Cliquez sur le bouton ci-dessous pour ajouter un produit.</div>
                }
            </div>

            <div className='d-flex justify-content-end' style={{marginTop: 20}}>
                <ButtonRectangle text='Ajouter un produit' color={'green'} onClick={displayProductAdding} />
            </div>
        </div>
    )
}
