import React from 'react';
import ButtonRectangle from '../../components/buttonRectangle';

export default function ActivateModal({show, cancel, reactivate}) {

    return (
        <div onClick={cancel} style={{zIndex: show ? 1 : -1}} className='modalBackground'>
            <div onClick={(event) => event.stopPropagation()} className='modalWhite'>
                <div style={{textAlign: 'center'}} className='greyText'>{"Êtes-vous sûr de vouloir réactiver cet utilisateur ?"}</div>

                <div style={{marginTop: 20}} className='d-flex justify-content-center'>
                    <ButtonRectangle style={{marginRight: 20}} onClick={cancel} text='Annuler' color={'grey'} />
                    <ButtonRectangle onClick={reactivate} text={'Réactiver'} color={'green'} />
                </div>
            </div>
        </div>
    )
}
