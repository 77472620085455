import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './product.scss'

export default function ProductRow({onClick, border, name, price, wheat, rapeseed, beet, potato}) {
    return (
        <div>
            {border ? 
                <div className='productBorder' />
            : null}
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div onClick={onClick} className='d-flex align-items-center' style={{flex: 1}}>
                    <div style={{marginRight: 10}} className='productName'>{name}</div>
                    <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faEdit} height={10} width={10} color='#b1b1b1' />
                </div>
                <div style={{flex: 1}}>
                    <div className='greyText'>{price}€</div>
                </div>
                <div style={{flex: 3, display: 'flex'}}>
                    <div style={{flex: 1, textAlign: 'center'}} className='basicText'>{wheat ?? '-'}</div>
                    <div style={{flex: 1, textAlign: 'center'}} className='basicText'>{rapeseed ?? '-'}</div>
                    <div style={{flex: 1, textAlign: 'center'}} className='basicText'>{beet ?? '-'}</div>
                    <div style={{flex: 1, textAlign: 'center'}} className='basicText'>{potato ?? '-'}</div>
                </div>
            </div>
        </div>
    )
}
