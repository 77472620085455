import React from 'react'
import ButtonRectangle from '../../../components/buttonRectangle'

export default function SortModal({show, dismiss, sortUser, setSortUser}) {

    return (
        <div style={{zIndex: show ? 1 : -1}} className='filterModal'>
            <div style={{marginBottom: 20}} className='greyText'>Trier par : </div>

            <div style={{marginBottom: 10}} className='d-flex align-items-center'>
                <input onChange={(event) => setSortUser(event.target.value)} name='sortUser' value='alphabeticalOrderAsc' className='filterCheckbox' type='radio'></input>
                <label className='greyText' htmlFor='alphabeticalOrderAsc'>Ordre alphabétique</label>
            </div>
            <div style={{marginBottom: 10}} className='d-flex align-items-center'>
                <input onChange={(event) => setSortUser(event.target.value)} name='sortUser' value='estimatesCountDesc' className='filterCheckbox' type='radio'></input>
                <label className='greyText' htmlFor='estimatesCountDesc'>Nombre de devis décroissant</label>
            </div>
            <div style={{marginBottom: 10}} className='d-flex align-items-center'>
                <input onChange={(event) => setSortUser(event.target.value)} name='sortUser' value='estimatesCountAsc' className='filterCheckbox' type='radio'></input>
                <label className='greyText' htmlFor='estimatesCountAsc'>Nombre de devis croissant</label>
            </div>
            <div style={{marginBottom: 10}} className='d-flex align-items-center'>
                <input onChange={(event) => setSortUser(event.target.value)} checked={sortUser === 'lastLogin'} name='sortUser' value='lastLogin' className='filterCheckbox' type='radio'></input>
                <label className='greyText' htmlFor='lastLogin'>Connexion la plus récente</label>
            </div>
            <div style={{marginBottom: 10}} className='d-flex align-items-center'>
                <input onChange={(event) => setSortUser(event.target.value)} name='sortUser' value='firstLogin' className='filterCheckbox' type='radio'></input>
                <label className='greyText' htmlFor='firstLogin'>Connexion la plus ancienne</label>
            </div>

            <div onClick={dismiss} className='d-flex justify-content-end' style={{marginTop: 10}}>
                <ButtonRectangle color='green' text='Ok' />
            </div>
        </div>
    )
}
