import { faCircleNotch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

export default function ButtonRectangle({color, text, onClick, style, disabled, loading}) {
    return (
        <Button disabled={disabled} variant='' className={`buttonRectangle ${color}`} style={{...style, opacity: disabled ? 0.5 : 1}} onClick={onClick}>
            {loading ? 
                <FontAwesomeIcon className='spinner' icon={faCircleNotch} height={20} width={20} />
                :
                text
            }
        </Button>
    )
}
