import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import './login.scss';
import logo from '../../images/logo_green.png'
import ButtonRectangle from '../../components/buttonRectangle';
import { useDispatch } from 'react-redux';
import { setToken } from '../../redux/actions';

export default function Login() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const DISABLED = loading || !username.length || !password.length

    useEffect(() => {
        return () => {
            setErrorMessage('')
        }
    }, [])

    const onKeyPress = (key) => {
        if(key === 'Enter'){
            authenticate()
        }
    }
    
    const authenticate = () => {
        setLoading(true)
        fetch(process.env.REACT_APP_API+'/auth-by-credentials-web', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: username,
                password: password
            })
        })
        .then(res => {return res.json()})
        .then(json => {
            if(json.success){
                dispatch(setToken(json.token))
                setLoading(false)
                navigate('/')
            }
            else{
                setErrorMessage(json.error)
                setLoading(false)
            }
        })
        .catch(err => {
            setErrorMessage("Une erreur s'est produite")
            setLoading(false)
        })
    }

    return (
        <Container className='children-centered backgroundPrimary'>
            <div className={'loginContainer'}>

                <img src={logo} alt='' width={80} />

                <p className='greyText'>Accès privé</p>

                <div className='d-flex flex-column align-items-center'>
                    <div className='d-flex align-items-center' style={{marginBottom: 20}}>
                        <FontAwesomeIcon className='iconPrimary' style={{position: 'absolute', marginLeft: 20}} icon={faUser} width={20} height={20} />
                        <input onChange={(event) => setUsername(event.target.value)} value={username} type='text' className='loginInput' placeholder="Nom d'utilisateur"></input>
                    </div>
                    <div className='d-flex align-items-center' style={{marginBottom: 20}}>
                        <FontAwesomeIcon className='iconPrimary' style={{position: 'absolute', marginLeft: 20}} icon={faKey} width={20} height={20} />
                        <input onKeyPress={(event) => onKeyPress(event.key)} onChange={(event) => setPassword(event.target.value)} value={password} type='password' className='loginInput' placeholder="Mot de passe"></input>
                    </div>
                </div>

                <ButtonRectangle disabled={DISABLED} loading={loading} color={'green'} text='Connexion' onClick={authenticate} />

                <div style={{marginTop: 10}} className='errorMessage'>{errorMessage}</div>

                <p className='forgottenPassword' onClick={() => navigate('/forgottenPassword')}>
                    Mot de passe oublié ?
                </p>

            </div>
        </Container>
    )
}
