import { faUserEdit, faUserMinus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import './user.scss'
import jwt_decode from "jwt-decode";

export default function UserCard({user, onEdit, onDelete, onReactivate}) {

    const token = useSelector(state => state.token)

    const DATE = new Date(user.last_login_date)

    const lastLoginDate = user.last_login_date ? (DATE.getDate() < 10 ? '0'+DATE.getDate() : DATE.getDate()) + '/' + (DATE.getMonth()+1 < 10 ? '0'+(DATE.getMonth()+1) : DATE.getMonth()+1) + '/' + DATE.getFullYear() : "Pas de connexion"
    const lastLoginHour = user.last_login_date ? (" à "+DATE.getHours()+":"+(DATE.getMinutes() < 10 ? '0'+DATE.getMinutes() : DATE.getMinutes())) : ""

    const decodedJwt = jwt_decode(token)
    
    return (
        <div className='userCard'>
            <div className='d-flex align-items-center'>
                <div className='userNameAndEstimate'>
                    <div className='greyText textEllipsis'>{user.first_name} {user.name}</div>
                    <div className='basicText textEllipsis userCardMail'>{user.email}</div>
                </div>

                <div className={user.devis_count > 0 ? 'estimateRectangle green estimateOver650' : 'estimateRectangle yellow estimateOver650'}>
                    <div><b>{user.devis_count}</b> devis</div>
                </div>
                
                <div className={user.devis_count > 0 ? 'estimateRectangle green estimateUnder650' : 'estimateRectangle yellow estimateUnder650'}>
                    <div><b>{user.devis_count}</b></div>
                </div>
            </div>

            <div className='flex-column align-items-center lastLoginOver770' style={{flex: 1}}>
                <div className='greyText textEllipsis'>{lastLoginDate}{lastLoginHour}</div>
                <div className='basicText textEllipsis'>dernière connexion</div>
            </div>

            <div className='flex-column align-items-center lastLoginUnder770' style={{flex: 1}}>
                <div className='greyText textEllipsis'>{lastLoginDate}</div>
            </div>

            {user.is_archived ? 
                <div className='maskUserCard' />
            : null}
            
            <div className='userActions'>
                {user.is_archived ? 
                    <div className='userIcons'>
                        <FontAwesomeIcon style={{cursor: 'pointer'}} onClick={onReactivate} icon={faUserPlus} width={20} height={20} color='white' />
                    </div> 
                    :
                    <div className='userIcons'>
                        <FontAwesomeIcon style={{cursor: 'pointer'}} onClick={onEdit} icon={faUserEdit} width={20} height={20} color='white' />
                        {user.uuid !== decodedJwt.user.uuid ? 
                            <FontAwesomeIcon style={{cursor: 'pointer'}} onClick={onDelete} icon={faUserMinus} width={20} height={20} color='#ff6e6e' />
                        : null}
                    </div>
                }
            </div>

        </div>
    )
}
