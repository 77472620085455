import React from 'react';
import SkeletonLoader from "tiny-skeleton-loader-react";

export default function ProductRowSkeleton() {
    return(
        <>
        <SkeletonLoader style={{height: 25, width: '100%', marginTop: 10, borderRadius: 5}} />
        <SkeletonLoader style={{height: 25, width: '100%', marginTop: 10, borderRadius: 5}} />
        <SkeletonLoader style={{height: 25, width: '100%', marginTop: 10, borderRadius: 5}} />
        <SkeletonLoader style={{height: 25, width: '100%', marginTop: 10, borderRadius: 5}} />
        <SkeletonLoader style={{height: 25, width: '100%', marginTop: 10, borderRadius: 5}} />
        <SkeletonLoader style={{height: 25, width: '100%', marginTop: 10, borderRadius: 5}} />
        </>
    )
}
