import React, { useEffect, useState } from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts'

import './graphic.scss'

export default function Graphic({kpis}) {
    
    const [parsedKpis, setParsedKpis] = useState(kpis?.sim_and_dev_count_by_week)

    useEffect(() => {
        if(kpis?.sim_and_dev_count_by_week){
            let copyKpis = [...kpis.sim_and_dev_count_by_week]
            copyKpis.forEach((kpi, i) => {
                let date = new Date(kpi.kpi_date)
                kpi.kpi_date = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate())+'/'+(date.getMonth() < 10 ? '0'+date.getMonth() : date.getMonth())
            })
            setParsedKpis(copyKpis)
        }
    }, [kpis])

    return (
        <div>
            <div className='d-flex justify-content-center'>
                <div style={{marginRight: 10}} className='d-flex justify-content-center align-items-center'>
                    <div className='square devis' />
                    <div className='d-flex'>
                        <div className='greyText'>Devis</div>
                    </div>
                </div>
                
                <div className='d-flex justify-content-center align-items-center'>
                    <div className='square simulation' />
                    <div className='d-flex'>
                        <div className='greyText'>Simulations</div>
                    </div>
                </div>
            </div>
            <ResponsiveContainer height={200} minWidth={300} width='99%'>
                <BarChart data={parsedKpis} margin={{ top: 5, right: 5, bottom: 5, left: 5 }} >
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="kpi_date" />
                    <YAxis allowDecimals={false} />
                    <Bar dataKey="kpi_devis" fill="#99d1ab" />
                    <Bar dataKey="kpi_simulation" fill="#008d2c" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}
