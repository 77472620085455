import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ButtonRectangle from '../../../components/buttonRectangle';

export default function UserModal({show, dismiss, isNew, user, refresh}) {

    const token = useSelector(state => state.token)
    
    const [name, setName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isAdmin, setIsAdmin] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(isNew){
            setName('')
            setFirstName('')
            setEmail('')
            setPassword('')
            setIsAdmin(false)
        }
        else{
            if(user){
                setName(user.name)
                setFirstName(user.first_name)
                setEmail(user.email)
                setIsAdmin(user.is_admin)
            }
        }
    }, [user, isNew])

    const createUser = () => {
        setLoading(true)
        fetch(process.env.REACT_APP_API+'/users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
            },
            body: JSON.stringify({
                first_name: firstName,
                name: name,
                email: email,
                password: password,
                is_admin: isAdmin ? 1 : 0
            })
        })
        .then(res => {return res.json()})
        .then(json => {
            if(json.success){
                setErrorMessage('')
                removeModal()
                refresh()
                setLoading(false)
            }
            else{
                setErrorMessage(json.message)
                setLoading(false)
            }
        })
        .catch(err => {
            setErrorMessage("Une erreur s'est produite")
            setLoading(false)
        })
    }

    const editUser = () => {
        setLoading(true)
        fetch(process.env.REACT_APP_API+'/users/'+user.uuid, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
            },
            body: JSON.stringify({
                first_name: firstName,
                name: name,
                email: email,
                is_admin: isAdmin ? 1 : 0
            })
        })
        .then(res => {return res.json()})
        .then(json => {
            if(json.success){
                setErrorMessage('')
                removeModal()
                refresh()
                setLoading(false)
            }
            else{
                setErrorMessage(json.message)
                setLoading(false)
            }
        })
        .catch(err => {
            setErrorMessage("Une erreur s'est produite")
            setLoading(false)
        })
    }

    const removeModal = () => {
        setErrorMessage('')
        if(isNew){
            setName('')
            setFirstName('')
            setEmail('')
            setPassword('')
            setIsAdmin(false)
        }
        else{
            setName(user.name)
            setFirstName(user.first_name)
            setEmail(user.email)
            setIsAdmin(user.is_admin)
        }
        dismiss()
    }

    const validateEmail = (email) => {
        return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(email)
    }

    const DISABLED = isNew ? !name.length || !firstName.length || !validateEmail(email) || password.length < 5 : !name.length || !firstName.length || !validateEmail(email) || loading

    return (
        <div onClick={removeModal} style={{zIndex: show ? 1 : -1}} className='modalBackground'>
            <div onClick={(event) => event.stopPropagation()} className='modalWhite'>
                <div className='title'>{isNew ? "Nouvel utilisateur" : user?.first_name+' '+user?.name}</div>
                <div style={{padding: 20}}>
                    <div className='d-flex'>
                        <div>
                            <div className='greenUnderline' />
                            <div className='greyText'>Informations</div>
                        </div>
                    </div>
                    <div className='d-flex flex-row' style={{marginTop: 20}}>
                        <div style={{flex: 1}}>
                            <div style={{marginTop: 10, height: 25}} className='greyText'>Nom</div>
                            <div style={{marginTop: 10, height: 25}} className='greyText'>Prénom</div>
                            <div style={{marginTop: 10, height: 25}} className='greyText'>Email</div>
                            {isNew ? 
                                <div style={{marginTop: 10, height: 25}} className='greyText'>Mot de passe</div>
                            : null}
                            <div style={{marginTop: 10, height: 25}} className='greyText'>Administrateur ?</div>
                        </div>

                        <div className='d-flex flex-column' style={{flex: 2}}>
                            <input value={name} onChange={(event) => setName(event.target.value)} type={'text'} className='basicInput' placeholder='-'></input>
                            <input value={firstName} onChange={(event) => setFirstName(event.target.value)} type={'text'} className='basicInput' placeholder='-'></input>
                            <input value={email} onChange={(event) => setEmail(event.target.value)} type={'email'} className='basicInput' placeholder='-'></input>
                            {isNew ? 
                                <input value={password} onChange={(event) => setPassword(event.target.value)} type={'text'} className='basicInput' placeholder='-'></input>
                            : null}
                            <input checked={isAdmin} onChange={(event) => setIsAdmin(event.target.checked)} className='basicCheckbox' type='checkbox'></input>
                        </div>
                    </div>
                </div>

                <div style={{marginBottom: 10}} className='errorMessage'>{errorMessage}</div>

                <div className='d-flex justify-content-center'>
                    <ButtonRectangle style={{marginRight: 20}} onClick={removeModal} text='Annuler' color={'grey'} />
                    <ButtonRectangle loading={loading} disabled={DISABLED} onClick={isNew ? createUser : editUser} text='Enregistrer' color={'green'} />
                </div>
            </div>
        </div>
    )
}
