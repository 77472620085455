import {actions} from './actions'

const defaultState = {
    token: '',
}

export function reducer(state = defaultState, action) {
    switch (action.type) {     
        case actions.TOKEN:
            return Object.assign({}, state, { token: action.option }); 
        default:
            return state;
    }
}
