import React from 'react';
import SkeletonLoader from "tiny-skeleton-loader-react";

export default function GraphicSkeleton() {
    return(
        <>
        <SkeletonLoader style={{height: 221, width: '100%'}} />
        </>
    )
}
