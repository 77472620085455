import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import ButtonRectangle from '../../components/buttonRectangle'

export default function ResetPassword() {
    const navigation = useNavigate()
    const { token } = useParams()

    const [loading, setLoading] = useState(false)
    const [isValid, setIsValid] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    useEffect(() => {
        verifToken()
    }, [])

    const verifToken = () => {
        setLoading(true)
        fetch(process.env.REACT_APP_API+'/check-forgot-password-token-validity', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: token
            })
        })
        .then(res => {return res.json()})
        .then(json => {
            setIsValid(json.isValid)
            setLoading(false)
        })
        .catch(err => {
            setErrorMessage("Une erreur s'est produite")
            setLoading(false)
        })
    }

    const changePassword = () => {
        if(password !== confirmPassword){
            setErrorMessage('Les mots de passe doivent être identiques.')
        }
        else{
            if(password.length < 5){
                setErrorMessage('Le mot de passe doit contenir au moins 5 caractères.')
            }
            else{
                setLoading(true)
                fetch(process.env.REACT_APP_API+'/change-password', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        token: token,
                        password: password
                    })
                })
                .then(res => {return res.json()})
                .then(json => {
                    setErrorMessage(json.message)
                    if(json.success){
                        setErrorMessage(json.message+' Vous allez être redirigé dans un instant...')
                        setLoading(false)
                        setTimeout(() => {
                            navigation('/login')
                        }, 3000)
                    }
                    else{
                        setLoading(false)
                    }
                })
                .catch(err => {
                    setErrorMessage("Une erreur s'est produite")
                    setLoading(false)
                })
            }
        }
    }

    const handleKeyPress = (key) => {
        if(key === 'Enter'){
            changePassword()
        }
    }
    
    return (
        <Container className='children-centered backgroundPrimary'>
            <div style={{maxWidth: 500}} className={'loginContainer d-flex flex-column align-items-center'}>
                {isValid ? 
                    <>
                    <div className='title mt-3'>Réinitialisation du mot de passe</div>
    
                    <div className='greyText mt-3'>Veuillez saisir votre nouveau mot de passe.</div>
    
                    <div className='d-flex align-items-center' style={{marginTop: 20}}>
                        <FontAwesomeIcon className='iconPrimary' style={{position: 'absolute', marginLeft: 20}} icon={faLock} width={20} height={20} />
                        <input onChange={(event) => setPassword(event.target.value)} value={password} type='password' className='loginInput' placeholder="Mot de passe"></input>
                    </div>
                    <div className='d-flex align-items-center' style={{marginTop: 20}}>
                        <FontAwesomeIcon className='iconPrimary' style={{position: 'absolute', marginLeft: 20}} icon={faLock} width={20} height={20} />
                        <input onKeyPress={event => handleKeyPress(event.key)} onChange={(event) => setConfirmPassword(event.target.value)} value={confirmPassword} type='password' className='loginInput' placeholder="Confirmer mot de passe"></input>
                    </div>
    
                    <ButtonRectangle onClick={changePassword} loading={loading} disabled={!password.length || !confirmPassword.length || loading} style={{marginTop: 20}} color='green' text='Réinitialiser' />
                    <div style={{marginTop: 10}} className='errorMessage'>{errorMessage}</div>
                    </>
                    :
                    <>
                        <div className='greyText'>Ce lien de récupération de mot de passe n'est plus valide.</div>
                        <ButtonRectangle loading={loading} onClick={() => navigation('/forgottenPassword')} disabled={loading} style={{marginTop: 20}} color='green' text='Demander un nouveau mot de passe' />
                    </>
                }
            </div>
        </Container>
    )
}
