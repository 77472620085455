import React from 'react';
import ButtonRectangle from '../../components/buttonRectangle';

export default function DeleteModal({show, cancel, remove, isProduct, loading}) {

    const getMessage = () => {
        let message = "Êtes-vous sûr de vouloir supprimer ?"
        if(isProduct){
            message = "Êtes-vous sûr de vouloir supprimer ce produit ?"
        }
        else{
            message = "Êtes-vous sûr de vouloir désactiver cet utilisateur ?"
        }
        return message
    }

    return (
        <div onClick={cancel} style={{zIndex: show ? 1 : -1}} className='modalBackground'>
            <div onClick={(event) => event.stopPropagation()} className='modalWhite'>
                <div style={{textAlign: 'center'}} className='greyText'>{getMessage()}</div>

                <div style={{marginTop: 20}} className='d-flex justify-content-center'>
                    <ButtonRectangle style={{marginRight: 20}} onClick={cancel} text='Annuler' color={'grey'} />
                    <ButtonRectangle disabled={loading} loading={loading} onClick={remove} text={isProduct ? 'Supprimer' : 'Désactiver'} color={'red'} />
                </div>
            </div>
        </div>
    )
}
