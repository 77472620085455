import React, { useEffect, useState } from 'react'
import ButtonRectangle from '../../../components/buttonRectangle'

export default function FilterModal({show, dismiss, filter}) {

    const [noEstimate, setNoEstimate] = useState(false)
    const [estimates, setEstimates] = useState(false)
    const [deactivatedUsers, setDeactivatedUsers] = useState(false)
    const [activatedUsers, setActivatedUsers] = useState(false)
    const [admins, setAdmins] = useState(false)

    useEffect(() => {
        filter(noEstimate, estimates, deactivatedUsers, activatedUsers, admins)
    }, [noEstimate, estimates, deactivatedUsers, activatedUsers, admins]) 

    return (
        <div style={{zIndex: show ? 1 : -1}} className='filterModal'>
            <div style={{marginBottom: 20}} className='greyText'>Filtrer par : </div>

            <div style={{marginBottom: 10}} className='d-flex align-items-center'>
                <input value={noEstimate} onChange={(event) => setNoEstimate(event.target.checked)} id='noEstimate' className='filterCheckbox' type='checkbox'></input>
                <label className='greyText' htmlFor='noEstimate'>Pas de devis</label>
            </div>
            <div style={{marginBottom: 10}} className='d-flex align-items-center'>
                <input value={estimates} onChange={(event) => setEstimates(event.target.checked)} id='estimates' className='filterCheckbox' type='checkbox'></input>
                <label className='greyText' htmlFor='estimates'>Au moins 1 devis</label>
            </div>
            <div style={{marginBottom: 10}} className='d-flex align-items-center'>
                <input value={deactivatedUsers} onChange={(event) => setDeactivatedUsers(event.target.checked)} id='deactivatedUsers' className='filterCheckbox' type='checkbox'></input>
                <label className='greyText' htmlFor='deactivatedUsers'>Utilisateurs désactivés</label>
            </div>
            <div style={{marginBottom: 10}} className='d-flex align-items-center'>
                <input value={activatedUsers} onChange={(event) => setActivatedUsers(event.target.checked)} id='activatedUsers' className='filterCheckbox' type='checkbox'></input>
                <label className='greyText' htmlFor='activatedUsers'>Utilisateurs non désactivés</label>
            </div>
            <div style={{marginBottom: 10}} className='d-flex align-items-center'>
                <input value={admins} onChange={(event) => setAdmins(event.target.checked)} id='admins' className='filterCheckbox' type='checkbox'></input>
                <label className='greyText' htmlFor='admins'>Administrateurs</label>
            </div>

            <div onClick={dismiss} className='d-flex justify-content-end' style={{marginTop: 10}}>
                <ButtonRectangle color='green' text='Ok' />
            </div>
        </div>
    )
}
