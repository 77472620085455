import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ButtonRectangle from '../../../components/buttonRectangle';
import './product.scss'

export default function ProductModal({show, dismiss, isNew, onDelete, product, refresh}) {

    const [name, setName] = useState('')
    const [price, setPrice] = useState('')

    const [base, setBase] = useState('')
    const [kcl, setKcl] = useState('')
    const [vin, setVin] = useState('')
    const [craie, setCraie] = useState('')
    const [dol, setDol] = useState('')
    const [sil, setSil] = useState('')
    const [champ, setChamp] = useState('')

    const [mo, setMo] = useState('')
    const [n, setN] = useState('')
    const [p, setP] = useState('')
    const [k, setK] = useState('')
    const [mgo, setMgo] = useState('')
    const [cao, setCao] = useState('')
    const [so3, setSo3] = useState('')
    const [equ, setEqu] = useState('')
    
    const [wheat, setWheat] = useState('')
    const [rapeseed, setRapeseed] = useState('')
    const [beet, setBeet] = useState('')
    const [potato, setPotato] = useState('')

    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const token = useSelector(state => state.token)

    const DISABLED = name === '' || price === '' || loading

    useEffect(() => {
        if(isNew){
            setName('')
            setPrice('')
            setBase('')
            setKcl('')
            setVin('')
            setCraie('')
            setDol('')
            setSil('')
            setChamp('')
            setMo('')
            setN('')
            setP('')
            setK('')
            setMgo('')
            setCao('')
            setSo3('')
            setEqu('')
            setWheat('')
            setRapeseed('')
            setBeet('')
            setPotato('')
        }
        else{
            if(product){
                setName(product.name)
                setPrice(product.price)
                setBase(product.base ?? '')
                setKcl(product.kcl ?? '')
                setVin(product.vin ?? '')
                setCraie(product.craie ?? '')
                setDol(product.dol ?? '')
                setSil(product.sil ?? '')
                setChamp(product.champ ?? '')
                setMo(product.matiere_organique ?? '')
                setN(product.azote ?? '')
                setP(product.phosphore ?? '')
                setK(product.potassium ?? '')
                setMgo(product.magnesium ?? '')
                setCao(product.oxyde_de_calcium ?? '')
                setSo3(product.trioxyde_de_souffre ?? '')
                setEqu(product.equivalence_chimique ?? '')
                setWheat(product.dose_per_hectare_for_wheat ?? '')
                setRapeseed(product.dose_per_hectare_for_rapeseed ?? '')
                setBeet(product.dose_per_hectare_for_beet ?? '')
                setPotato(product.dose_per_hectare_for_potato ?? '')
            }
        }
    }, [product, isNew])

    const removeModal = () => {
        setErrorMessage('')
        if(isNew){
            setName('')
            setPrice('')
            setBase('')
            setKcl('')
            setVin('')
            setCraie('')
            setDol('')
            setSil('')
            setChamp('')
            setMo('')
            setN('')
            setP('')
            setK('')
            setMgo('')
            setCao('')
            setSo3('')
            setEqu('')
            setWheat('')
            setRapeseed('')
            setBeet('')
            setPotato('')
        }
        else{
            setName(product.name)
            setPrice(product.price)
            setBase(product.base ?? '')
            setKcl(product.kcl ?? '')
            setVin(product.vin ?? '')
            setCraie(product.craie ?? '')
            setDol(product.dol ?? '')
            setSil(product.sil ?? '')
            setChamp(product.champ ?? '')
            setMo(product.matiere_organique ?? '')
            setN(product.azote ?? '')
            setP(product.phosphore ?? '')
            setK(product.potassium ?? '')
            setMgo(product.magnesium ?? '')
            setCao(product.oxyde_de_calcium ?? '')
            setSo3(product.trioxyde_de_souffre ?? '')
            setEqu(product.equivalence_chimique ?? '')
            setWheat(product.dose_per_hectare_for_wheat ?? '')
            setRapeseed(product.dose_per_hectare_for_rapeseed ?? '')
            setBeet(product.dose_per_hectare_for_beet ?? '')
            setPotato(product.dose_per_hectare_for_potato ?? '')
        }
        dismiss()
    }
    
    const addProduct = () => {
        setLoading(true)
        fetch(process.env.REACT_APP_API+'/products', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
            },
            body: JSON.stringify({
                "name": name,
                "price": parseFloat(price),
                "base": parseFloat(base),
                "kcl": parseFloat(kcl),
                "vin": parseFloat(vin),
                "craie": parseFloat(craie),
                "dol": parseFloat(dol),
                "sil": parseFloat(sil),
                "champ": parseFloat(champ),
                "matiere_organique": parseFloat(mo),
                "azote": parseFloat(n),
                "phosphore": parseFloat(p),
                "potassium": parseFloat(k),
                "magnesium": parseFloat(mgo),
                "oxyde_de_calcium": parseFloat(cao),
                "trioxyde_de_souffre": parseFloat(so3),
                "equivalence_chimique": parseFloat(equ),
                "dose_per_hectare_for_wheat": parseFloat(wheat),
                "dose_per_hectare_for_rapeseed": parseFloat(rapeseed),
                "dose_per_hectare_for_beet": parseFloat(beet),
                "dose_per_hectare_for_potato": parseFloat(potato),
            })
        })
        .then(res => {return res.json()})
        .then(json => {
            if(json.success){
                setErrorMessage('')
                removeModal()
                refresh()
                setLoading(false)
            }
            else{
                setErrorMessage(json.error)
                setLoading(false)
            }
        })
        .catch(err => {
            setErrorMessage("Une erreur s'est produite")
            setLoading(false)
        })
    }
    
    const editProduct = () => {
        setLoading(true)
        fetch(process.env.REACT_APP_API+'/products/'+product.uuid, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
            },
            body: JSON.stringify({
                "name": name,
                "price": parseFloat(price),
                "base": parseFloat(base),
                "kcl": parseFloat(kcl),
                "vin": parseFloat(vin),
                "craie": parseFloat(craie),
                "dol": parseFloat(dol),
                "sil": parseFloat(sil),
                "champ": parseFloat(champ),
                "matiere_organique": parseFloat(mo),
                "azote": parseFloat(n),
                "phosphore": parseFloat(p),
                "potassium": parseFloat(k),
                "magnesium": parseFloat(mgo),
                "oxyde_de_calcium": parseFloat(cao),
                "trioxyde_de_souffre": parseFloat(so3),
                "equivalence_chimique": parseFloat(equ),
                "dose_per_hectare_for_wheat": parseFloat(wheat),
                "dose_per_hectare_for_rapeseed": parseFloat(rapeseed),
                "dose_per_hectare_for_beet": parseFloat(beet),
                "dose_per_hectare_for_potato": parseFloat(potato),
            })
        })
        .then(res => {return res.json()})
        .then(json => {
            if(json.success){
                setErrorMessage('')
                removeModal()
                refresh()
                setLoading(false)
            }
            else{
                setErrorMessage(json.error)
                setLoading(false)
            }
        })
        .catch(err => {
            setErrorMessage("Une erreur s'est produite")
            setLoading(false)
        })
    }

    const getTotal = () => {
        let total = 0
        if(!Number.isNaN(parseFloat(base))){
            total += parseFloat(base)
        }
        if(!Number.isNaN(parseFloat(kcl))){
            total += parseFloat(kcl)
        }
        if(!Number.isNaN(parseFloat(vin))){
            total += parseFloat(vin)
        }
        if(!Number.isNaN(parseFloat(craie))){
            total += parseFloat(craie)
        }
        if(!Number.isNaN(parseFloat(dol))){
            total += parseFloat(dol)
        }
        if(!Number.isNaN(parseFloat(sil))){
            total += parseFloat(sil)
        }
        if(!Number.isNaN(parseFloat(champ))){
            total += parseFloat(champ)
        }
        return total
    }
    
    return (
        <div onClick={removeModal} style={{zIndex: show ? 1 : -1}} className='modalBackground'>
            <div onClick={(event) => event.stopPropagation()} className='modalProduct'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='title'>{isNew ? "Ajouter un produit" : product?.name}</div>
                    {!isNew ? 
                        <ButtonRectangle onClick={onDelete} text='Supprimer' color='red' /> 
                    : null}
                </div>
                <div style={{padding: 20}}>

                    <div className='d-flex' style={{justifyContent: 'flex-start', marginBottom: 20}}>

                        <div className='d-flex flex-column' style={{marginRight: 100}}>
                            <div className='d-flex'>
                                <div>
                                    <div className='greenUnderline' />
                                    <div className='greyText'>Informations</div>
                                </div>
                            </div>

                            <div className='d-flex' style={{marginTop: 20}}>
                                <div className='d-flex flex-row' style={{marginRight: 20}}>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>Nom</div>

                                    <div className='d-flex flex-column' style={{flex: 2}}>
                                        <input value={name} onChange={(event) => setName(event.target.value)} type={'text'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    </div>
                                </div>
                                
                                <div className='d-flex flex-row'>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>€/T départ</div>

                                    <div className='d-flex flex-column' style={{flex: 2}}>
                                        <input value={price} onChange={(event) => setPrice(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex' style={{justifyContent: 'space-between', marginBottom: 20}}>

                        <div className='d-flex flex-column' style={{marginRight: 20}}>
                            <div className='d-flex'>
                                <div>
                                    <div className='greenUnderline' />
                                    <div className='greyText'>Composition</div>
                                </div>
                            </div>
                            <div className='d-flex flex-row' style={{marginTop: 20}}>
                                <div >
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>Base</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>KCl</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>Vin</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>Craie</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>Dol</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>Sil</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>Champ</div>
                                    <div className='compositionTotal' style={{height: 25}}>TOTAL</div>
                                </div>

                                <div className='d-flex flex-column' style={{flex: 2}}>
                                    <input value={base} onChange={(event) => setBase(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={kcl} onChange={(event) => setKcl(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={vin} onChange={(event) => setVin(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={craie} onChange={(event) => setCraie(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={dol} onChange={(event) => setDol(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={sil} onChange={(event) => setSil(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={champ} onChange={(event) => setChamp(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <div className='compositionTotal' style={{marginLeft: 20}}>{getTotal() > 0 ? getTotal().toFixed(2)+'%' : '-'}</div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='d-flex flex-column' style={{marginRight: 20}}>
                            <div className='d-flex'>
                                <div>
                                    <div className='greenUnderline' />
                                    <div className='greyText'>Apports</div>
                                </div>
                            </div>
                            <div className='d-flex flex-row' style={{marginTop: 20}}>
                                <div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>MO (%)</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>N (kg/T)</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>P (kg/T)</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>K (+Na) (kg/T)</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>MgO (kg/T)</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>CaO (kg/T)</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>SO3 (kg/T)</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>Equ. Chim.</div>
                                </div>

                                <div className='d-flex flex-column'>
                                    <input value={mo} onChange={(event) => setMo(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={n} onChange={(event) => setN(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={p} onChange={(event) => setP(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={k} onChange={(event) => setK(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={mgo} onChange={(event) => setMgo(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={cao} onChange={(event) => setCao(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={so3} onChange={(event) => setSo3(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={equ} onChange={(event) => setEqu(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-column'>

                            <div className='d-flex'>
                                <div>
                                    <div className='greenUnderline' />
                                    <div className='greyText'>Dose à l'Ha</div>
                                </div>
                            </div>
                            <div className='d-flex flex-row' style={{marginTop: 20}}>
                                <div >
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>Blé</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>Colza</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>Betterave</div>
                                    <div style={{marginTop: 10, height: 25}} className='greyText'>PdT</div>
                                </div>

                                <div className='d-flex flex-column' style={{flex: 2}}>
                                    <input value={wheat} onChange={(event) => setWheat(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={rapeseed} onChange={(event) => setRapeseed(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={beet} onChange={(event) => setBeet(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                    <input value={potato} onChange={(event) => setPotato(event.target.value)} type={'number'} style={{width: 100}} className='basicInput' placeholder='-'></input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{marginBottom: 10}} className='errorMessage'>{errorMessage}</div>

                <div className='d-flex justify-content-center'>
                    <ButtonRectangle style={{marginRight: 20}} onClick={removeModal} text='Annuler' color={'grey'} />
                    <ButtonRectangle loading={loading} disabled={DISABLED} onClick={isNew ? addProduct : editProduct} text='Enregistrer' color={'green'} />
                </div>
            </div>
        </div>
    )
}
