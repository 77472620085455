import './App.scss'
import { Routes, Route, Navigate } from "react-router-dom"
import Login from './pages/login'
import Dashboard from './pages/dashboard'
import ForgottenPassword from './pages/forgottenPassword'

import { Provider, useSelector } from 'react-redux'

import { createStore } from 'redux'
import { reducer } from './redux/reducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react'
import ResetPassword from './pages/resetPassword'


const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)
const store = createStore(persistedReducer)
const persistor = persistStore(store)

const MyRoutes = () => {
    const token = useSelector(state => state.token)

    return(
        <Routes>
            <Route path="/" element={token.length ? <Dashboard /> : <Navigate to='/login' />} />
            <Route path="/login" element={token.length ? <Navigate to='/' /> : <Login />} />
            <Route path='/forgottenPassword' element={<ForgottenPassword />} />
            <Route path='/reset-password/:token' element={<ResetPassword />} />
            <Route path='*' element={<Navigate to='/' />} />
        </Routes>
    )
}

export default function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <MyRoutes />
                </PersistGate>
            </Provider>
        </div>
    )
}
